<template>
<div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
        <v-card-title>
            <v-row>
                <v-col cols="12" sm="12" md="8">
                    <span class="card-label font-weight-bolder text-dark">
                    Proyectos Alianzas </span><br />
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
                        {{ datosAlianza.nombreInstrumento }}
                    </span>
                   
                </v-col>
                <v-col cols="12" sm="12" md="4">
                    <v-btn
                    color="light-blue-502"
                    dark
                    class="white--text mb-2 float-right"
                    @click="modalNuevo"
                    >
                    Registrar proyecto
                    </v-btn>
                </v-col>
            </v-row>
            
            <v-row class="col-md-12 m-0 p-0">
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
      
        <v-card-text>
            <v-tabs
                v-model="tabGrid"
                background-color="light-blue-502"
                centered
                dark
                icons-and-text
            >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                    En verificación
                    <v-icon>mdi-check</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                    Todos los proyectos
                    <v-icon>mdi-format-list-bulleted-square</v-icon>
                </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tabGrid" touchless>
                    <v-tab-item :value="'tab-1'">
                        <v-card flat>
                            <v-card-text>

                                <!--incio:: listado alianzas -->
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            class="elevation-0"
                                            :headers="headers"
                                            :items="alianzas"
                                            :search="filtro"
                                            :loading="tableLoading"
                                            :footer-props="{
                                                showFirstLastPage: true,
                                                firstIcon: 'mdi-page-first',
                                                lastIcon: 'mdi-page-last',
                                                prevIcon: 'mdi-chevron-left',
                                                nextIcon: 'mdi-chevron-right',
                                                'items-per-page-text': 'Registros por página',
                                                pageText: '{0}-{1} de {2}'
                                            }"
                                        >
                                            <template v-slot:top>
                                                <!-- v-container, v-col and v-row are just for decoration purposes. -->
                                                <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12" md="6" sm="6" xs="12">
                                                        <v-text-field
                                                            autocomplete="off"
                                                            class=""
                                                            dense
                                                            filled
                                                            color="blue-grey lighten-2"
                                                            v-model="filtro"
                                                            label="Nombre del proyecto.."
                                                            maxlength="200"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" md="6" sm="6" xs="12">
                                                        <v-select
                                                            v-model="estado"
                                                            :items="estados"
                                                            :loading="false"
                                                            dense
                                                            filled
                                                            label="Estado"
                                                            :no-data-text="'No existen estados registrados'"
                                                            menu-props="auto"
                                                            return-object
                                                        ></v-select>
                                                    </v-col>

                                                    <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                                                        <v-btn
                                                            class="ma-0 white--text d-none"
                                                            medium
                                                            color="light-blue darken-2"
                                                        >
                                                            <v-icon left>mdi-magnify</v-icon> Buscar
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </template>

                                        <template v-slot:item="{ item }">
                                            <tr>
                                            <td>{{item.id}}</td>
                                            <td>{{ item.nombreProyecto }}</td>
                                            <td class="text-right">{{ item.fechaInicio }}</td>
                                            <td class="text-right">{{ item.fechaFinalizacion }}</td>
                                            <td>
                                                <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue-grey lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 3
                                                    ? 'teal lighten-4'
                                                    : item.estadosId === 4
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue-grey lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 3
                                                    ? 'teal lighten-1'
                                                    : item.estadosId === 4
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                                </v-chip>
                                            </td>
                                            <td>
                                                <!--<v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="descargarArchivo(item.documentoFormalizacion)"
                                                >
                                                <v-icon left>mdi-download</v-icon> Documento de formalización
                                                </v-btn>-->

                                                <v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="mostrarModalActualizar(item.id, item.nombreProyecto)"
                                                >
                                                <v-icon left>mdi-eye</v-icon> Ver información
                                                </v-btn>

                                                <!-- <v-btn
                                                v-if="item.estadosId === 1 || item.estadosId === 4"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize "
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="prepararEnvio(item.id)"
                                                >
                                                <v-icon left>mdi-send</v-icon> Enviar
                                                </v-btn>-->

                                                <!-- <v-btn
                                                v-if="item.estadosId === 3"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                :to="{
                                                    name: 'proyectos-alianzas',
                                                    params: {
                                                        alianzaId: $CryptoJS.AES.encrypt(item.id.toString(), 'KEYADMINCNS2022').toString()
                                                    }
                                                }"
                                                >
                                                <v-icon left>mdi-file</v-icon> Proyectos
                                                </v-btn> -->

                                                <v-menu
                                                top
                                                :close-on-click="true"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    color="orange darken-2"
                                                    icon
                                                    fab
                                                    small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="item.asignacionActores === 0"
                                                    >
                                                    <v-icon color="orange darken-2">info</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item>
                                                    <v-list-item-title>Debe agregar roles y actores para poder registrar iniciativas.</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                                </v-menu>

                                                <v-btn
                                                v-if="item.estadosId === 7"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="mostrardialogEnvio(item.nombre)"
                                                >
                                                <v-icon left>mdi-power</v-icon> Activar
                                                </v-btn>


                                                <v-btn v-if="item.estadosId===2"
                                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                    small
                                                    depressed
                                                    color="blue-grey lighten-5"
                                                    @click="mostrarModalAprobacionRechazo(1,item.id, item.nombreProyecto)"
                                                >
                                                    <v-icon left>mdi-check-all</v-icon> Aprobar
                                                </v-btn>

                                                <v-btn v-if="item.estadosId===2"
                                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                    small
                                                    depressed
                                                    color="blue-grey lighten-5"
                                                    @click="mostrarModalAprobacionRechazo(2,item.id, item.nombreProyecto)"
                                                >
                                                    <v-icon left>mdi-close-circle-outline</v-icon> Rechazar
                                                </v-btn>

                                                <v-btn v-if="item.estadosId === 2"
                                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                        small
                                                        depressed
                                                        color="blue-grey lighten-5"
                                                        @click="mostrarDialogCorreccion(item.id, item.nombreProyecto)"
                                                    >
                                                    <v-icon left>mdi-playlist-edit</v-icon> Enviar corrección
                                                </v-btn> 



                                                <!--<v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                :to="{
                                                    name: 'seguimiento-iniciativa',
                                                    params: {
                                                    iniciativaId: $CryptoJS.AES.encrypt(
                                                        item.id.toString(),
                                                        'KEYADMINCNS2022'
                                                    ).toString()
                                                    }
                                                }"
                                                >
                                                <v-icon left>mdi-arrow-collapse-right</v-icon> Seguimiento
                                                </v-btn>-->

                                                <!-- <v-btn v-if="item.estadosId===1"
                                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                    small
                                                    depressed
                                                    color="blue-grey lighten-5"
                                                    @click="mostrarModalEnviarAlianza(item.id, item.nombreInstrumento)"
                                                >
                                                <v-icon left>mdi-send</v-icon> Enviar
                                                </v-btn> -->

                                                
                                            </td>
                                            </tr>
                                        </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                                <!--fin:: listado alianzas -->
                            </v-card-text>
                        </v-card>
                    </v-tab-item>


                    <v-tab-item  :value="'tab-2'">

                        <v-card flat>
                            <v-card-text>

                                <!--incio:: listado alianzas -->
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            class="elevation-0"
                                            :headers="headersAll"
                                            :items="alianzasAll"
                                            :search="filtroAll"
                                            :loading="tableLoadingAll"
                                            :footer-props="{
                                                showFirstLastPage: true,
                                                firstIcon: 'mdi-page-first',
                                                lastIcon: 'mdi-page-last',
                                                prevIcon: 'mdi-chevron-left',
                                                nextIcon: 'mdi-chevron-right',
                                                'items-per-page-text': 'Registros por página',
                                                pageText: '{0}-{1} de {2}'
                                            }"
                                        >
                                            <template v-slot:top>
                                                <!-- v-container, v-col and v-row are just for decoration purposes. -->
                                                <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12" md="6" sm="6" xs="12">
                                                        <v-text-field
                                                            autocomplete="off"
                                                            class=""
                                                            dense
                                                            filled
                                                            color="blue-grey lighten-2"
                                                            v-model="filtroAll"
                                                            label="Nombre del proyecto.."
                                                            maxlength="200"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" md="6" sm="6" xs="12">
                                                        <v-select
                                                            v-model="estado"
                                                            :items="estados"
                                                            :loading="false"
                                                            dense
                                                            filled
                                                            label="Estado"
                                                            :no-data-text="'No existen estados registrados'"
                                                            menu-props="auto"
                                                            return-object
                                                        ></v-select>
                                                    </v-col>

                                                    <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                                                        <v-btn
                                                            class="ma-0 white--text d-none"
                                                            medium
                                                            color="light-blue darken-2"
                                                        >
                                                            <v-icon left>mdi-magnify</v-icon> Buscar
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </template>

                                        <template v-slot:item="{ item }">
                                            <tr>
                                            <td>{{item.id}}</td>
                                            <td>{{ item.nombreProyecto }}</td>
                                            <td class="text-right">{{ item.fechaInicio }}</td>
                                            <td class="text-right">{{ item.fechaFinalizacion }}</td>
                                            <td>
                                                <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue-grey lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 3
                                                    ? 'teal lighten-4'
                                                    : item.estadosId === 4
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue-grey lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 3
                                                    ? 'teal lighten-1'
                                                    : item.estadosId === 4
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                                </v-chip>
                                            </td>
                                            <td>
                                                <!--<v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="descargarArchivo(item.documentoFormalizacion)"
                                                >
                                                <v-icon left>mdi-download</v-icon> Documento de formalización
                                                </v-btn>-->

                                                <v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="mostrarModalActualizar(item.id, item.nombreProyecto)"
                                                >
                                                <v-icon left>mdi-eye</v-icon> Ver información
                                                </v-btn>

                                                <!-- <v-btn
                                                v-if="item.estadosId === 1 || item.estadosId === 4"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize "
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="prepararEnvio(item.id)"
                                                >
                                                <v-icon left>mdi-send</v-icon> Enviar
                                                </v-btn>-->

                                               <!--  <v-btn
                                                v-if="item.estadosId === 3"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                :to="{
                                                    name: 'proyectos-alianzas',
                                                    params: {
                                                        alianzaId: $CryptoJS.AES.encrypt(item.id.toString(), 'KEYADMINCNS2022').toString()
                                                    }
                                                }"
                                                >
                                                <v-icon left>mdi-file</v-icon> Proyectos
                                                </v-btn> -->

                                                <v-menu
                                                top
                                                :close-on-click="true"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    color="orange darken-2"
                                                    icon
                                                    fab
                                                    small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="item.asignacionActores === 0"
                                                    >
                                                    <v-icon color="orange darken-2">info</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item>
                                                    <v-list-item-title>Debe agregar roles y actores para poder registrar iniciativas.</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                                </v-menu>

                                                <v-btn
                                                v-if="item.estadosId === 7"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="mostrardialogEnvio(item.nombre)"
                                                >
                                                <v-icon left>mdi-power</v-icon> Activar
                                                </v-btn>



                                                <!--<v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                :to="{
                                                    name: 'seguimiento-iniciativa',
                                                    params: {
                                                    iniciativaId: $CryptoJS.AES.encrypt(
                                                        item.id.toString(),
                                                        'KEYADMINCNS2022'
                                                    ).toString()
                                                    }
                                                }"
                                                >
                                                <v-icon left>mdi-arrow-collapse-right</v-icon> Seguimiento
                                                </v-btn>-->

                                                <!-- <v-btn v-if="item.estadosId===1"
                                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                    small
                                                    depressed
                                                    color="blue-grey lighten-5"
                                                    @click="mostrarModalEnviarAlianza(item.id, item.nombreInstrumento)"
                                                >
                                                <v-icon left>mdi-send</v-icon> Enviar
                                                </v-btn> -->

                                                
                                            </td>
                                            </tr>
                                        </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                                <!--fin:: listado alianzas -->
                            </v-card-text>
                        </v-card>


                    </v-tab-item>
            </v-tabs-items>

        </v-card-text>

    </v-card>


    <!-- inicio: dialog de registro -->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1024px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
        <v-card tile>
            <v-card-title>
                {{ accion === 1 ? `Registrar proyecto` : `Actualizar | ` + nombreProyecto }}

                <v-spacer></v-spacer>
                <v-btn
                    icon
                    :disabled="btnRegistroLoading"
                    @click="
                    dialogRegistro = false;"
                    class="float-right"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            
            </v-card-title>
            <v-divider class="d-none"></v-divider>

            <v-card-text  class="pl-0 pr-0 pt-0">
                <v-tabs
                    v-model="tab"
                    background-color="light-blue-502"
                    centered
                    dark
                    icons-and-text
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-1">
                        Datos Generales
                        <v-icon>mdi-format-list-bulleted-square</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2" :disabled="accion===1">
                        Vinculación a marcos de desarrollo
                        <v-icon>mdi-map-outline</v-icon>
                    </v-tab>

                    <v-tab href="#tab-3" :disabled="accion===1">
                        Socios
                        <v-icon>mdi-account-group</v-icon>
                    </v-tab>
                    <!--<v-tab href="#tab-4" :disabled="accion===1">
                    Vinculación a marcos de desarrollo
                    <v-icon>mdi-map-outline</v-icon>
                    </v-tab>-->

                    <v-tab href="#tab-4" :disabled="accion===1">
                        Cobertura
                        <v-icon>mdi-map-outline</v-icon>
                    </v-tab>



                    <v-tab href="#tab-5" :disabled="accion===1">
                        Contactos
                        <v-icon>mdi-account-box-outline</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item :value="'tab-1'">
                        <v-card flat>
                            <v-card-text>
                                <SeccionDatosGeneralesProyectosAlianza ref="seccionDatosGeneralesProyectosAlianza" :alianzaId="alianzaId" :proyectoId="proyectoId" :accion="accion"  @get-items="obtenerProyectos" @habilitar-secciones="habilitarSecciones" @actualizar-proyecto-id="actualizarProyectoId" :tipo="2" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item :value="'tab-2'">
                        <v-card flat>
                            <v-card-text>
                                <SeccionVinculacionProyectosAlianza ref="seccionVinculacionProyectosAlianza" :alianzaId="alianzaId" :proyectoId="proyectoId" :accion="accion" :tipo="2" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    

                    <v-tab-item :value="'tab-3'">
                        <v-card flat>
                            <v-card-text>
                                <SeccionSociosProyectosAlianza ref="seccionSociosProyectosAlianza" :proyectoAlianzaId="proyectoId" :accion="accion"  @get-items="obtenerProyectos" :tipo="2" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item :value="'tab-4'">
                        <v-card flat>
                            <v-card-text>
                                <SeccionCoberturaProyectosAlianza ref="seccionCoberturaProyectosAlianza" :proyectoAlianzaId="proyectoId" :alianzaId="alianzaId" :tipo="2" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                
                    <v-tab-item :value="'tab-5'">
                        <v-card flat>
                            <v-card-text>
                                <SeccionContactosProyectosAlianza ref="seccionContactosProyectosAlianza" :proyectoAlianzaId="proyectoId" :alianzaId="alianzaId" :accion="accion"  @get-items="obtenerProyectos" :tipo="2" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    

                    
                </v-tabs-items>



                
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- fin:: dialog registro alianza-->



     <!--begin:: dialog envio alianza -->
    <v-dialog v-model="dialogEnvio" width="500"  transition="scroll-y-transition" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Enviar proyecto de alianza

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogEnvio = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
                <p class="text-h6 black--text">
                    {{nombreInstrumento}}
                </p>
                <p class="text-subtitle-1">
                    
                    Está seguro de {{nuevoEstado===3 ? `Aprobar` : `Rechazar`}} el proyecto seleccionado? 
                </p>

                <v-row>
                    <!--inicio:: campo -->
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-textarea
                            dense
                            filled
                            v-model="comentarios"
                            class=""
                            color="blue-grey lighten-2"
                            label="Ingrese sus comentarios"
                            :rules="[
                                maxLength('comentarios', 500)
                            ]"
                            rows="3"
                            maxlength="500"
                        >
                        </v-textarea>
                    </v-col>
                    <!--fin:: campo-->
                    </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogEnvio = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="enviarAlianza"
            :loading="btnEnvioLoading"
          >
            Sí, enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog envío-->

    <!--begin:: dialog correccion -->
    <v-dialog v-model="dialogCorreccion" persistent width="500"  transition="scroll-y-transition">
      <v-card>
        <v-card-title class="text-h5">
          Corrección de proyecto alianza

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnAprobacionLoading"
            transition="dialog-bottom-transition"
            @click="dialogCorreccion = false; comentarios=''"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form  ref="formAprobacion" v-on:submit.prevent="enviarAlianza()" v-model="validFormCorreccion">
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                Ingrese sus comentarios de corrección/correcciones 
              </p>
              <v-row>
                <v-col cols="12">

                  <v-textarea
                     autocomplete="off"
                     class="required"
                     dense
                     filled
                     rows="4"
                     color="blue-grey lighten-2"
                     v-model="comentarios"
                     label="Agregue sus comentarios"
                     :rules="[
                       required('comentarios'),
                        minLength('comentarios', 10),
                        maxLength('comentarios', 500)
                      ]"
                      maxlength="500"
                    >
                  
                  </v-textarea>
                  <!--<div
                    class="blue-grey lighten-5 pa-4 text-center secondary rounded-lg"
                  >
                    <p class="text-subtitle-1">
                      {{ datosItem.descripcion }}
                    </p>
                  </div>-->
                </v-col>
              </v-row>
            </v-col>
          </v-row>

           <v-row>
           
            <v-col cols="12" md="12" sm="12">
                <v-spacer></v-spacer>
                <v-btn
                    color="green--text darken-1--text"
                    text
                    class="float-right"
                    type="submit"
                    :loading="btnAprobacionLoading"
                    :disabled="!validFormCorreccion"
                >
                    <v-icon>mdi-check-all</v-icon>
                    Enviar Corrección
                </v-btn>

                <v-btn
                    color="blue-grey darken-2"
                    text
                    class="float-right"
                    @click="dialogCorreccion = false"
                    :disabled="btnAprobacionLoading"
                >
                Cancelar
              </v-btn>

              
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
       
      </v-card>
    </v-dialog>
    <!--end: dialog correcciones-->


    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
</div>

</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

import DownloadFile from "@/core/untils/downloadfile.js";

import { OBTENER_PROYECTOS_ALIANZA, ENVIAR_PROYECTO_ALIANZA } from "@/core/services/store/alianzas/proyectos/proyectoalianza.module";

import SeccionDatosGeneralesProyectosAlianza from "@/view/pages/alianzas/proyectos/secciones/SeccionDatosGeneralesProyectosAlianza.vue";
import SeccionVinculacionProyectosAlianza from "@/view/pages/alianzas/proyectos/secciones/SeccionVinculacionProyectosAlianza.vue";
import SeccionSociosProyectosAlianza from "@/view/pages/alianzas/proyectos/secciones/SeccionSociosProyectosAlianza.vue";
import SeccionCoberturaProyectosAlianza from "@/view/pages/alianzas/proyectos/secciones/SeccionCoberturaProyectosAlianza.vue";
import SeccionContactosProyectosAlianza from "@/view/pages/alianzas/proyectos/secciones/SeccionContactosProyectosAlianza.vue";
//import { OBTENER_INICIATIVAS } from "@/core/services/store/negociaciones/iniciativadirecta.module";

export default {
    name: "AdminAlianzas",
    components: {
        DialogLoader,
        SnackAlert,
        SeccionDatosGeneralesProyectosAlianza,
        SeccionVinculacionProyectosAlianza,
        SeccionSociosProyectosAlianza,
        SeccionCoberturaProyectosAlianza,
        SeccionContactosProyectosAlianza
    },
    data() {
        return { 
            accion: 1,
            tableLoading: false,
            tabGrid: "tab-1",
            alianzas: [],
            alianzaId: 0,
            alianzasAll: [],
            filtroAll: "",
            tableLoadingAll: false,
            nombreInstrumento: "",
            dialogRegistro: false,
            modalTitle: "Registrar alianza",
            btnRegistroLoading: false,
            tab: "tab-1",
            dialogEnvio: false,
            nuevoEstado: 0,
            btnEnvioLoading: false,
            comentarios: "",
            parameters:[],
            datosAlianza:{},
            proyectoId:0,
            dialogCorreccion: false,
            btnAprobacionLoading: false,
            validFormCorreccion: false,
            ...validations
        }
    },

    methods: {

        /*
        * Obtener datos de la alianza
        */
       /* async obtenerDatosProyecto(alianzaId){
            this.accion = 2;
            this.datosAlianza = [];
            await this.$store
            .dispatch(OBTENER_INSTRUMENTO_ALIANZA, alianzaId)
            .then(res => {
                if (res.status === 200) {
                    this.datosAlianza = res.data;
                    this.fechaSuscripcion = this.datosAlianza.fechaSuscripcion;
                    this.fechaFinalizacion = this.datosAlianza.fechaFinalizacion;
                    if(this.datosAlianza.temporalidadAlianza == 0 ){
                        this.cuentaTemporalidadSeleccionada = { id: 2, text:"No"};
                    } else {
                        this.cuentaTemporalidadSeleccionada = { id: 1, text:"Si"};
                    }
                }
                this.skeletonLoading = false;
            })
            .catch(() => {
                this.datosAlianza = [];
            });
        }, */
    
        modalNuevo() {
            this.tab = "tab-1";
            this.accion = 1;
            this.proyectoId=0;
            this.modalTitle = "Registrar proyecto";
            if(this.$refs.seccionDatosGeneralesProyectosAlianza){
                this.$refs.seccionDatosGeneralesProyectosAlianza.obtenerTiposInstrumento();
                this.$refs.seccionDatosGeneralesProyectosAlianza.obtenerClasificacionesRecursos();
                
                this.$refs.seccionDatosGeneralesProyectosAlianza.resetItems(2);
                            
            }

            if(this.$refs.seccionVinculacionProyectosAlianza){
                this.$refs.seccionVinculacionProyectosAlianza.resetItems(2);
            }
                    
            if(this.$refs.seccionSociosProyectosAlianza){
                this.$refs.seccionSociosProyectosAlianza.resetItems(2);
                this.$refs.seccionSociosProyectosAlianza.obtenerTiposSocio();
            }

            if(this.$refs.seccionCoberturaProyectosAlianza){
                this.$refs.seccionCoberturaProyectosAlianza.resetItems(2);
                this.$refs.seccionCoberturaProyectosAlianza.obtenerDepartamentos();
            }
            

            if(this.$refs.seccionContactosProyectosAlianza){
                this.$refs.seccionContactosProyectosAlianza.resetItems(2);
            }

            if(this.$refs.seccionSociosProyectosAlianza){
                this.$refs.seccionSociosProyectosAlianza.resetItems(2);
            }

            this.dialogRegistro = true;
        },

        /*
        * Obtener las alianzas registradas
        */
        async obtenerProyectos(){
            
            this.tableLoading = true;

            this.alianzas = [];
            await this.$store
            .dispatch(OBTENER_PROYECTOS_ALIANZA, {estadoId: 2, alianzaId: this.alianzaId})
            .then(res => {

                if(res.status == 200) {
                    this.alianzas = res.data;
                }

                this.tableLoading = false;
            })
            .catch(() => {
                this.tableLoading = false;
            });
        },


        /*
        * Obtener todos los proyectos registrados
        */
        async obtenerProyectosAll(){
            
            this.tableLoadingAll = true;

            this.alianzasAll = [];
            await this.$store
            .dispatch(OBTENER_PROYECTOS_ALIANZA, {estadoId: 0, alianzaId: this.alianzaId})
            .then(res => {

                if(res.status == 200) {
                    this.alianzasAll = res.data;
                }

                this.tableLoadingAll = false;
            })
            .catch(() => {
                this.tableLoadingAll = false;
            });
        },


        mostrarModalActualizar(proyectoId, nombreProyecto){
            this.nombreProyecto = nombreProyecto;
            this.modalTitle = "Actualizar proyecto";
            this.proyectoId = proyectoId;
            this.accion = 2;
            this.resetDatosSecciones();
            this.dialogRegistro = true;
        },

        resetDatosSecciones(){
            if(this.$refs.seccionDatosGeneralesProyectosAlianza){
                this.$refs.seccionDatosGeneralesProyectosAlianza.obtenerTiposInstrumento();
                this.$refs.seccionDatosGeneralesProyectosAlianza.obtenerClasificacionesRecursos();

                if(this.accion===2){
                    this.$refs.seccionDatosGeneralesProyectosAlianza.obtenerDatosProyecto(this.proyectoId);
                }
                
                this.$refs.seccionDatosGeneralesProyectosAlianza.resetItems(1);
                            
            }

            if(this.$refs.seccionVinculacionProyectosAlianza){
                this.$refs.seccionVinculacionProyectosAlianza.resetItems(2);
                this.$refs.seccionVinculacionProyectosAlianza.obtenerCoberturaProyecto(this.alianzaId);
            }

            if(this.$refs.seccionSociosProyectosAlianza){
                this.$refs.seccionSociosProyectosAlianza.resetItems(1);
                this.$refs.seccionSociosProyectosAlianza.obtenerSocios(this.alianzaId);
                this.$refs.seccionSociosProyectosAlianza.obtenerTiposSocio();
            }


            if(this.$refs.seccionCoberturaProyectosAlianza){
                this.$refs.seccionCoberturaProyectosAlianza.resetItems(1);
                this.$refs.seccionCoberturaProyectosAlianza.obtenerCoberturas(this.alianzaId);
                this.$refs.seccionCoberturaProyectosAlianza.obtenerDepartamentos();
            }

            if(this.$refs.seccionContactosProyectosAlianza){
                this.$refs.seccionContactosProyectosAlianza.obtenerCargos().then(() => {
                    this.$refs.seccionContactosProyectosAlianza.obtenerTiposContacto().then(() => {
                        this.$refs.seccionContactosProyectosAlianza.obtenerContactos(this.alianzaId);
                    });

                })
            }

        },

        /*
        * Habilitar las secciones para actualizar la información
        */
       habilitarSecciones(){
        this.accion=2;
       },


       ///Actualizar el id de la alianza
       /*actualizarAlianzaId(nuevoId){
        this.alianzaId = nuevoId;
       },*/


       ///Actualizar el id del proyecto
       actualizarProyectoId(nuevoId){
        this.proyectoId = nuevoId;
       },


       /*
       * Mostrar el modal para el envío del proyecto
       */
      mostrarModalEnviarAlianza(id, nombre){
        this.proyectoId = id;
        this.nombreInstrumento = nombre;
        this.dialogEnvio=true;
      },


      async enviarAlianza(){
       
        this.btnEnvioLoading = true;

        this.$store
            .dispatch(ENVIAR_PROYECTO_ALIANZA, {proyectosAlianzasId: this.proyectoId, estadosAlianzasId: this.nuevoEstado, comentarios: this.comentarios})
            .then(res => {
            this.btnEnvioLoading = false;
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.comentarios="";
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                    this.obtenerProyectos();
                    this.obtenerProyectosAll();
                    this.dialogCorreccion = false;
                    this.dialogEnvio = false;
                   // this.resetForm();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnEnvioLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`
                );
                this.btnEnvioLoading = false;
            });
      },


        /*
        * Mostrar el modal para el envío de la alianza
        */
        mostrarModalAprobacionRechazo(tipo,id, nombre){
            this.proyectoId = id;
            this.nombreInstrumento = nombre;
            tipo === 1 ? this.nuevoEstado = 3 : this.nuevoEstado = 8; 
            this.dialogEnvio=true;
        },

        //Mostrar el dialogo para enviar correcciones
        mostrarDialogCorreccion(id, descripcion){
            this.nombreInstrumento = descripcion;
            this.proyectoId = id;
            this.nuevoEstado = 9; 
            this.dialogCorreccion = true;
        },

      //Descargar el archivo
      descargarArchivo(path){
        DownloadFile.download(path);
      }



    },

    created(){
        


        this.parameters = this.$route.params;
        let alId = this.$CryptoJS.AES.decrypt(this.parameters.alianzaId,"KEYADMINCNS2022").toString(this.CryptoJS.enc.Utf8);


        this.alianzaId = parseInt(alId);
        this.obtenerProyectos();
        this.obtenerProyectosAll();
        //this.obtenerDatosProyecto(this.alianzaId);
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Inicio", route: "dashboard" },
            { title: "Alianzas" },
            { title: "Proyectos" }
        ]);
    },

    computed: {
        headers() {
            return [
                {
                    text: "Codigo",
                    align: "start",
                    sortable: false,
                    value: "codigo"
                },
                {
                    text: "Nombre del proyecto",
                    align: "start",
                    sortable: true,
                    value: "nombreProyecto"
                },
                {
                    text: "Fecha Suscripción",
                    align: "start",
                    sortable: true,
                    value: "fechaSuscripcion"
                },
                {
                    text: "Fecha Finalización",
                    align: "start",
                    sortable: true,
                    value: "fechaFinalizacion"
                },
                {
                    text: "Estado",
                    align: "start",
                    sortable: true,
                    value: "estado",
                    filter: this.filtroEstado
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: false,
                    value: ""
                }
            ];
        },

        headersAll() {
            return [
                {
                    text: "Codigo",
                    align: "start",
                    sortable: false,
                    value: "codigo"
                },
                {
                    text: "Nombre del proyecto",
                    align: "start",
                    sortable: true,
                    value: "nombreProyecto"
                },
                {
                    text: "Fecha de inicio",
                    align: "start",
                    sortable: true,
                    value: "fechaSuscripcion"
                },
                {
                    text: "Fecha Finalización",
                    align: "start",
                    sortable: true,
                    value: "fechaFinalizacion"
                },
                {
                    text: "Estado",
                    align: "start",
                    sortable: true,
                    value: "estado",
                    filter: this.filtroEstado
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: false,
                    value: ""
                }
            ];
        },
    }
}

</script>